.headerBg {
    background-color: #262626;
}

.navItems {
    color: #F6F6F6;
}

.logoText {
    color: #BDBDBD;
}

.footer {
    padding: 10px;
    text-align: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 5%;
    background-color: #00356b;
}

.blur-overflow {
    position: relative;
    -webkit-mask-image: linear-gradient(to top, transparent 0%, black 48px, black calc(100% - 48px), transparent 100%);
    mask-image: linear-gradient(to top, transparent 0%, black 48px, black calc(100% - 48px), transparent 100%);
}

.blur-overflow-content {
    overflow: auto;
    backdrop-filter: blur(10px);
}

.blur-overflow-content::-webkit-scrollbar {
    width: 10px;
}

.blur-overflow-content::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 5px;
}

.blur-overflow-content::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    border-radius: 5px;
}

/* home layout styles */
.homeHeaderInput {
    box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
}

.homeHeaderInput::placeholder {
    color: #262626;
}

.text-activeLink {
    color: blue;
    /* or any other desired color */
}

.gradient-element {
    background: linear-gradient(90deg, #00356B 70%, #5FA5F9 100%);
}