.card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
}

/* On mouse-over, add a deeper shadow */
.card:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

/* Add some padding inside the card container */
.container {
    padding: 2px 16px;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.container-1 {
    padding: 21px 50px;
    margin: 0;
    position: relative;
}

.input-control {
    border: 1px solid #949494;
    padding: 1px;
}

/* home page styles */

.jumboTron {
    background-color: #4A4A4A;
}

.cardContainer, .cardContainerWithoutHover {
    border: 2px solid #050100;
    border-radius: 15px;
}

.cardContainer:hover,
.homeCard:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.homeCard {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border-radius: 10px;
}

.homeCard-completed {
    background-color: #60D100;
    border-radius: 10px;
}

.homeCard-incomplete {
    background-color: transparent;
    border-radius: 10px;
}

.viewButton {
    width: 50%;
    border-radius: 15px;
    padding: 5px;
}

.viewButton-active {
    background-color: #00356b;
}

.viewButton-disabled {
    background-color: #E7E7E7;
}

.scenarioBg {
    color: #BDBDBD;
}

.masterCard {
    background-color: #FFFFFF;
}

.masterCard:hover {
    background-color: #F7F7F7;
}

.btn {
    /* border: 3px solid #6c7e8a; */
    background-color: #00356b;
    color: #FFEEEE;
    border-radius: 10px;
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
}

.loginBtn {
    background-color: #00356b;
    border-radius: 10px;
}

.bgFooter {
    background-color: #00356b;
}

input[type=text], input[type=password] {
    border-radius: 10px;
    padding-left: 5px;
}

.linkButton {
    color: #0036B1;
}

.card-shadow {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.help-container {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f7f7f7;
}

.help-card {
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.loginBtn {
    transition: background-color 0.3s ease;
    display: block;
    width: 100%;
    text-align: center;
}

.loginBtn:hover {
    background-color: #0053ba;
}